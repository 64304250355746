export default [
  {
    id: "reminders",
    description: "Reminders for the actual day",
    active: true
  },
  {
    id: "prescribers",
    description: "Prescribers updating for demo",
    active: false
  }
];
